function events() {
  let events = new Map();

  function addEvent(type, el, func) {
    const id = `e${0 | (Math.random() * 10000)}`;
    el.dataset.eventId = id;
    events.set(id, {
      id,
      type,
      el,
      func,
    });
    el.addEventListener(type, func);
  }

  function removeEvent(el) {
    if (el) {
      const ev = events.get(el.dataset.eventId);
      if (ev) {
        el.removeEventListener(ev.type, ev.func);
        events.delete(el.dataset.eventId);
      }
    }
  }

  function removeEvents(...els) {
    els.forEach((el) => removeEvent(el));
  }

  return {
    addEvent,
    removeEvent,
    removeEvents,
  };
}

export default events();
