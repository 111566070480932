import events from "./utils/events";

export default function FormToggle(el) {
  const { addEvent, removeEvents } = events;
  const checkboxes = el.querySelectorAll('[name="type"]');
  const forms = el.querySelectorAll("form");

  const startIndex = +[...checkboxes].find((check) => check.checked).value;
  forms.forEach((form) => form.classList.add("hidden"));
  forms[startIndex].classList.remove("hidden");

  checkboxes.forEach((box) =>
    addEvent("change", box, (e) => {
      const index = +e.target.value;
      forms.forEach((form) => form.classList.add("hidden"));
      forms[index].classList.remove("hidden");
    })
  );

  return () => {
    removeEvents(...checkboxes);
  };
}
