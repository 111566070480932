import SlimSelect from "slim-select";
import eventBus from "./utils/eventBus";

function MultiSelect(el) {
  const placeholder = el.dataset?.placeholder ?? "";
  const showSearch = el.dataset?.showSearch == "true";

  function initSelect() {
    return new SlimSelect({
      select: el,
      placeholder: placeholder,
      allowDeselectOption: true,
      showContent: "down",
      showSearch,
    });
  }

  let select = initSelect();

  eventBus.on("form:clear", () => {
    if (el.name !== "order") {
      el.selectedIndex = -1;
      select.destroy();
      select = initSelect();
    }
  });

  return () => {
    select.destroy();
  };
}

export default MultiSelect;
