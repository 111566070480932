import Headroom from "headroom.js";

export default function Header(el) {
  const headroom = new Headroom(el, {
    offset: 250,
  });
  headroom.init();

  return () => {};
}
