import barba from "@barba/core";
import "core-js/stable";
import "regenerator-runtime/runtime";

import lazySizes from "lazysizes";
// import RangeSlider from "./js/RangeSlider";
import Slider from "./js/Slider";
import GMap from "./js/GMap";
import MultiSelect from "./js/MultiSelect";
import PropertyFilter from "./js/PropertyFilter";
import FormToggle from "./js/FormToggle";
import CurrencyConvertor from "./js/CurrencyConvertor";
import "./app.scss";
import eventBus from "./js/utils/eventBus";
import Toggle from "./js/Toggle";
import Popout from "./js/Popout";
import PropertyListing from "./js/PropertyListing";
import Form from "./js/Form";
import Spinner from "./js/Spinner";
import Gallery from "./js/Gallery";
import PriceField from "./js/PriceField";
import Header from "./js/Header";
import Video from "./js/Video";

barba.init({
  //debug: true,
  //logLevel: "error",
  timeout: 5000,
});

let actions = {};
let actionChain = new Map();
function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

// registerAction(RangeSlider);
registerAction(GMap);
registerAction(Slider);
registerAction(MultiSelect);
registerAction(PropertyFilter);
registerAction(FormToggle);
registerAction(CurrencyConvertor);
registerAction(Toggle);
registerAction(Popout);
registerAction(PropertyListing);
registerAction(Form);
registerAction(Spinner);
registerAction(Gallery);
registerAction(PriceField);
registerAction(Header);
registerAction(Video);

/*
  To include modules, import and include them anywhere in your JS. For example,
  uncommenting the following two lines will force the jquery chunk to be
  generated. Normally, only code that is actually used will be output as modules.
*/
// import $ from "jquery"
// console.log( $ );

// Only used in dev
// if (module.hot) {
//   module.hot.accept();
// }

runActions();

window.lastUrl = window.location.href + window.location.search;
window.shouldUpdateHistory = false;

function setMainActions() {
  eventBus.on("clear-actions", clearActions);
  eventBus.on("run-actions", (root) => runActions(root));
  eventBus.on("go", (url) => {
    window.lastUrl = window.location.href + window.location.search;
    window.shouldUpdateHistory = true;
    barba.go(url);
  });
}

setMainActions();

barba.hooks.beforeEnter((data) => {
  clearActions();
});

barba.hooks.after((data) => {
  runActions();
  // const hash = window?.location?.hash;
  const hash = data?.next?.url?.hash ?? "";
  const target = document.querySelector(hash.length ? "#" + hash : undefined);
  if (target) {
    console.log({
      hash,
      target,
    });
    window.scroll(0, window.pageYOffset + target.getBoundingClientRect().top);
  } else {
    window.scroll(0, 0);
  }

  document.querySelectorAll(".alert-script").forEach((as) => as?.remove());

  const alertForm = document.querySelector(".alert-form");
  if (alertForm) {
    document.body.append(
      ...[...alertForm.querySelectorAll("script")].map((script) => {
        const newScript = document.createElement("script");
        newScript.className = "alert-script";
        if (script.src) {
          newScript.src = script.src;
        }
        if (script.innerHTML) {
          newScript.innerHTML = script.innerHTML;
        }
        return newScript;
      })
    );
  }
});

function runActions(root = document) {
  root.querySelectorAll("[data-action]").forEach((el) => {
    const action = el.dataset.action;
    let actionId = el.dataset.id;

    if (!actionChain.get(actionId)) {
      actionId = `a${0 | (Math.random() * 10000)}`;
      el.dataset.id = actionId;

      if (actions[action]) {
        actionChain.set(actionId, {
          el,
          unmount: actions[action].mount(el)?.bind?.({}),
        });
      }
    }
  });

  // if (window.shouldUpdateHistory) {
  //   console.log("Last url", window.lastUrl);
  //   barba.history.add(window.lastUrl);
  //   window.shouldUpdateHistory = false;
  // }
  console.log("Last url", window.lastUrl);
}

function clearActions(root = document.body) {
  actionChain.forEach((ac, key) => {
    if (root.contains(ac.el)) {
      ac?.unmount?.();
      actionChain.delete(key);
    }
  });
  console.log(actionChain);
  if (root === document.body) {
    eventBus.all.clear();
    setMainActions();
  }
}
