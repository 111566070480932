import eventBus from "./utils/eventBus";
import events from "./utils/events";

export default function Spinner(el) {
  const { addEvent, removeEvents } = events;
  const input = el.querySelector("[data-input]");
  const down = el.querySelector("[data-down]");
  const up = el.querySelector("[data-up]");

  let value = input.value;

  addEvent("click", up, () => {
    if (value < 8) {
      value++;
    }
    updateDisplay();
  });

  addEvent("click", down, () => {
    if (value > 1) {
      value--;
    }
    updateDisplay();
  });

  eventBus.on("form:clear", () => {
    value = 1;
    updateDisplay();
  });

  function updateDisplay() {
    input.value = value;
    const evt = new Event("change");
    input.form.dispatchEvent(evt);
  }

  return () => {
    removeEvents(up, down);
  };
}
