import events from "./utils/events";

export default function Toggle(el) {
  const { addEvent, removeEvents } = events;
  const target = document.querySelector(el.dataset?.target);

  addEvent("click", el, () => {
    target.classList.toggle(el.dataset?.class);
  });

  return () => {};
}
