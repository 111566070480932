import Flickity from "flickity";
import events from "./utils/events";

function Slider(el) {
  el.classList.remove("hidden");
  const { addEvent, removeEvents } = events;
  const options = el.dataset?.options ? JSON.parse(el.dataset.options) : {};
  const indexEl = el.parentElement.querySelector("[data-index]");

  const flk = new Flickity(el, options);

  function nextSlide() {
    flk.next();
  }

  flk.on("change", (index) => {
    if (indexEl) indexEl.innerText = index + 1;
  });

  function prevSlide() {
    flk.previous();
  }

  const nextButton = document.querySelector(options?.nextButton);
  if (nextButton) addEvent("click", nextButton, nextSlide);

  const prevButton = document.querySelector(options?.prevButton);
  if (prevButton) addEvent("click", prevButton, prevSlide);

  return () => {
    console.log("destroy slider ");
    removeEvents(nextButton, prevButton);
    flk.destroy();
  };
}

export default Slider;
