import events from "./utils/events";

function CurrencyConvertor(el) {
  const { addEvent, removeEvents } = events;
  const price = Number(el.dataset.price);
  const bitcoin = el.querySelector("[data-bitcoin]");
  const eth = el.querySelector("[data-eth]");
  const usd = el.querySelector("[data-usd]");
  const modal = el.querySelector("[data-modal]");

  addEvent("click", el, () => {
    console.log("show currency");
    modal.toggleAttribute("hidden");
  });

  fetch("https://api.coinbase.com/v2/exchange-rates?currency=USD")
    .then((r) => r.json())
    .then((r) => {
      const rates = r.data.rates;
      console.log(rates);
      usd.innerText = " " + price.toLocaleString();

      if (bitcoin) {
        let exchangeRate = rates["BTC"];
        let exchanged = Number(exchangeRate) * Number(price);
        bitcoin.innerText = " ~ " + exchanged.toLocaleString();
      }

      if (eth) {
        let exchangeRate = rates["ETH"];
        let exchanged = Number(exchangeRate) * Number(price);
        eth.innerText = " ~ " + exchanged.toLocaleString();
      }
    })
    .catch((err) => {
      el.setAttribute("hidden", true);
    });

  return () => {
    removeEvents(el);
  };
}

export default CurrencyConvertor;
