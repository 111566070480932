import { width, height } from "./consts";

export default async function getLand(
  formData,
  page,
  postsPerPage,
  fullQuery = true
) {
  const siteUrl = window?.siteUrl ?? "/";
  let query = `section: "property", type: "land", `;

  const provinces = formData.getAll("province[]");
  const order = formData.get("order");
  const min = formData.get("min") ? formData.get("min") : 0;
  const max = formData.get("max") ? formData.get("max") : 999999999;
  const beach = formData.get("beach");
  const reference = formData.get("search");

  if (provinces.length) {
    query += `province: [${provinces.join(",")}], `;
  }

  if (beach?.length) {
    query += `beachCheckbox: ${beach == "on"}, `;
  }

  if (reference?.length) {
    query += `reference: "${reference}", `;
  }

  query += `price: ["and", ">= ${min}", "<= ${max}"], `;

  query += `orderBy: "${order}", `;

  query += `limit: ${postsPerPage}, offset: ${(page - 1) * postsPerPage}`;

  let graphQl = `{
    entryCount(${query})
    entries(${query}) {
      url
      title
      ... on property_land_Entry {
        typeHandle
        size
        price
        poa
        bitcoin
        ethereum
        investmentOpportunity
        province(limit: 1) {
          title
        }
        photos {
          webp: url@transform(width: ${width}, height: ${height}, format: "webp")
          jpg:  url@transform(width: ${width}, height: ${height}, format: "jpg")
        }
      }
    }
  }`;

  const response = await fetch(`${siteUrl}api`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: graphQl,
    }),
  });
  const json = await response.json();
  return json.data;
}
