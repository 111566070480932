function getStatHTML(title, value, stat) {
  const tmp = stat.cloneNode(true);
  tmp.querySelector("[data-stat-title]").innerHTML = title;
  tmp.querySelector("[data-stat-value]").innerHTML = value;
  return tmp.outerHTML;
}

function createElementFromHTML(htmlString) {
  const div = document.createElement("div");
  div.innerHTML = htmlString.trim();
  return div.firstChild;
}

export default function propertyCard(
  property,
  propertyCardTemplate,
  index = 0
) {
  const card = createElementFromHTML(propertyCardTemplate);
  const stats = card.querySelector("[data-stats]");
  const stat = card.querySelector("[data-stat]");
  const info = card.querySelector("[data-info]");
  const images = card.querySelector("[data-images]");
  const link = card.querySelector("a");
  const bitcoin = card.querySelector("[data-bitcoin]");
  const eth = card.querySelector("[data-eth]");
  const op = card.querySelector("[data-op]");

  link.href = property.url;

  card.style.animationDelay = `${index * 0.1}s`;

  let statHTML = "";
  if (property.beds) {
    statHTML += getStatHTML("Beds", property.beds, stat);
  }

  if (property.baths) {
    statHTML += getStatHTML("Baths", property.baths, stat);
  }

  if (property.size) {
    statHTML += getStatHTML("Size (m<sup>2</sup>)", property.size, stat);
  }

  if (property.photos.length) {
    const rnd = () => Math.floor(Math.random() * 10000);
    const prevBtnClass = "btn" + rnd();
    const nextBtnClass = "btn" + rnd();

    if (property.photos.length > 1) {
      images.dataset.action = "Slider";
      let options = JSON.parse(images.dataset.options);
      options.nextButton = "." + nextBtnClass;
      options.prevButton = "." + prevBtnClass;
      card.querySelector(".property-card__prev").classList.add(prevBtnClass);
      card.querySelector(".property-card__next").classList.add(nextBtnClass);
      card.querySelector("[data-images-btns]").classList.remove("hidden");
      images.dataset.options = JSON.stringify(options);
    }
    images.innerHTML = property.photos
      .map((image) => {
        return `<div class='w-full'>
          <div class="w-full pb-[66.666%] relative property-card__hover overflow-hidden ">
              <picture class='absolute inset-0 block'>
                  <source type="image/webp" data-srcset="${image.webp}"/>
                  <img class='w-full lazyload' data-src="${image.jpg}" alt="${property.title}">
              </picture>
          </div>
        </div>`;
      })
      .join("");
  }

  let infoHTML = "";

  if (property.typeHandle == "property") {
    infoHTML += (property.commercial ? "Commercial" : "Residential") + " / ";
  } else {
    infoHTML += "Land / For Sale / ";
  }

  if (property.purchaseType?.length) {
    infoHTML += property.purchaseType[0].title + " / ";
  }

  if (property.province?.length) {
    infoHTML += property.province[0].title + " / ";
  }

  if (property.investmentOpportunity) {
    op.classList.remove("hidden");
  }

  if (property.bitcoin) {
    bitcoin.classList.remove("hidden");
  }

  if (property.ethereum) {
    eth.classList.remove("hidden");
  }

  if (property.poa) {
    infoHTML += `<span class='inline-block whitespace-nowrap'>POA</span>`;
  } else {
    const conv = `<div data-action='CurrencyConvertor' data-price="${
      property.price
    }" class="relative inline-block z-50">
      <button class='flex bg-gold rounded-full w-5 h-5 px-1 justify-center items-center shadow appearance-none transition-all hover:bg-gold-dark text-white font-medium'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
        </svg>
      </button>
      <div hidden data-modal class="absolute bg-white shadow-lg rounded left-1/2 transform -translate-x-1/2 text-left">
        <div title='US Dollar' class="border-b border-gray-200 px-2 whitespace-nowrap py-1">
          $:
          <span data-usd></span>
        </div>
        ${
          property.bitcoin
            ? `<div title='Bitcoin' class="border-b border-gray-200 px-2 whitespace-nowrap py-1">
          ₿:
          <span data-bitcoin></span>
        </div>`
            : ""
        }
        
        ${
          property.ethereum
            ? `<div title='Ethereum' class=" px-2 whitespace-nowrap py-1">
          Ξ:
          <span data-eth></span>
        </div>`
            : ""
        }
      </div>
    </div>`;

    infoHTML += `<span class='inline-block whitespace-nowrap'>
          $&nbsp;${property.price.toLocaleString()}
          ${property.bitcoin || property.ethereum ? conv : ""}
      </span>`;
  }

  info.innerHTML = infoHTML;
  stats.innerHTML = statHTML;

  return card.outerHTML;
}
