import * as noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";
import "../css/range.scss";
import events from "./utils/events";
import eventBus from "./utils/eventBus";

function drawChart(ctx, prices) {
  ctx.canvas.width = 100;
  ctx.fillStyle = "#C7C7C7";
  const maxPrice = [...prices].pop();

  let amounts = [];

  for (let i = 0; i < ctx.canvas.width; i += 10) {
    const amount = prices.filter((price) => {
      return (
        price >= (i / 100) * maxPrice && price <= ((i + 10) / 100) * maxPrice
      );
    }).length;

    amounts.push(amount);
  }

  const highest = [...amounts].sort().pop();
  const ratio = ctx.canvas.height / highest;

  amounts.forEach((amount, i) => {
    const height = amount * ratio;
    ctx.fillRect(i * 10, ctx.canvas.height - height, 9, height);
  });
}

export default function PriceField(el) {
  const { addEvent, removeEvents } = events;
  const modal = el.querySelector("[data-modal]");
  const openBtn = el.querySelector("[data-open]");
  const clearBtn = el.querySelector("[data-price-clear]");
  const saveBtn = el.querySelector("[data-save]");
  const min = el.querySelector('[name="min"]');
  const max = el.querySelector('[name="max"]');
  const range = el.querySelector("[data-range]");
  const chart = el.querySelector("[data-chart]");
  const prices = JSON.parse(el.dataset.prices);
  const ctx = chart.getContext("2d");
  const backdrop = el.querySelector("[data-backdrop]");
  let slider;

  if (prices.length) {
    drawChart(ctx, prices);
    const topPrice = [...prices].pop();

    slider = noUiSlider.create(range, {
      start: [0, topPrice],
      step: 500,
      connect: true,
      range: {
        min: 0,
        max: topPrice,
      },
    });
  }

  slider?.on("slide", (data) => {
    min.value = +data[0];
    max.value = +data[1];
  });

  function open() {
    backdrop.classList.remove("hidden");
    modal.classList.remove("hidden");
    min.focus();
  }

  function close() {
    backdrop.classList.add("hidden");
    updateText();
    modal.classList.add("hidden");
  }

  updateText();

  addEvent("click", backdrop, () => {
    close();
  });

  addEvent("click", openBtn, () => {
    if (modal.classList.contains("hidden")) {
      open();
    } else {
      close();
    }
  });

  function clear() {
    min.value = undefined;
    max.value = undefined;
    updateText();
    close();
  }

  addEvent("click", clearBtn, () => {
    clear();
  });

  function updateText() {
    let minText = "Any";
    if (min.value) {
      minText = "$ " + min.value;
    }
    let maxText = "Any";
    if (max.value) {
      maxText = "$ " + max.value;
    }
    if (min.value || max.value) {
      openBtn.innerText = `${minText} - ${maxText}`;
    } else {
      openBtn.innerText = "Price / Any";
    }

    const evt = new Event("change");
    min.form.dispatchEvent(evt);
  }

  addEvent("click", saveBtn, () => {
    updateText();
    close();
  });

  addEvent("input", min, (e) => {
    console.log(e.target.value);
  });

  eventBus.on("form:clear", () => {
    clear();
  });

  eventBus.on("close-price", close);

  return () => {
    removeEvents(openBtn, clearBtn, saveBtn, backdrop);
    slider?.destroy?.();
  };
}
