import api from "!../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../node_modules/postcss-loader/dist/cjs.js!../node_modules/svg-transform-loader/encode-query.js!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[4]!../node_modules/mini-css-extract-plugin/dist/loader.js??ruleSet[1].rules[9].use[0]!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[9].use[1]!../node_modules/postcss-loader/dist/cjs.js!../node_modules/svg-transform-loader/encode-query.js!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[9].use[4]!./app.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};