import events from "./utils/events";
import eventBus from "./utils/eventBus";

function PropertyFilter(el) {
  const { addEvent, removeEvents } = events;
  const clear = el.querySelector("[data-clear]");
  const order = document.querySelector('[name="order"]');
  let orderValue = "postDate desc";
  const mainSearch = el.hasAttribute("data-main");

  // addEvent("submit", el, (e) => {
  //   e.preventDefault();
  //   submit();
  // });

  function submit() {
    eventBus.emit("close-price");
    if (typeof FormData !== undefined) {
      const formData = new FormData(el);
      formData.set("order", orderValue);
      const search = new URLSearchParams(formData);
      const url = `${el.action}?${search.toString()}`;

      formData.set("action", el.action);

      if (el.dataset.handle == "homepage" || el.dataset.handle == "popout") {
        eventBus.emit("go", url + "#listings");
      } else {
        window.history.pushState({}, "", url);
        eventBus.emit("update-properties", formData);
      }
    }
  }

  if (order && mainSearch) {
    addEvent("change", order, (e) => {
      orderValue = order.value;
      submit();
    });
  }

  addEvent("change", el, () => {
    clear?.classList.remove("opacity-0");
  });

  if (clear) {
    addEvent("click", clear, (e) => {
      console.log("clear");
      el.reset();
      clear.classList.add("opacity-0");
      eventBus.emit("multi-select:reset", el);
      eventBus.emit("form:clear");
      if (mainSearch) {
        submit();
      }
    });
  }

  eventBus.on("listing-ready", () => {
    // el.submit();
    if (el.dataset.handle != "popout") {
      submit();
      console.log("listing ready");
    }
  });

  return () => {
    // let els = [el, el, order];
    // if (clear) {
    //   els.push(clear);
    // }
    removeEvents(el, el, order, clear);
  };
}

export default PropertyFilter;
