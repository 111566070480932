import eventBus from "./utils/eventBus";
import getProperties from "./utils/getProperties";
import propertyCard from "./utils/propertyCard";
import events from "./utils/events";
import getLand from "./utils/getLand";

export default async function PropertyListing(el) {
  let lastFormData;
  const { addEvent, removeEvents } = events;
  const loadMore = document.querySelector("[data-loadmore]");
  const siteUrl = window?.siteUrl ?? "/";
  const results = document.querySelector("[data-listing-count]");
  let currentPage = 1;
  let total = 0;

  const propertyCardTemplate = await (
    await fetch(`${siteUrl}property/property-card`)
  ).text();

  async function updateProperties(formData, page = 1, postsPerPage = 9) {
    lastFormData = formData;
    currentPage = page;
    const action = formData.get("action");
    if (page == 1) {
      el.innerHTML = `<div class='pl-8 w-full'><img class='block mx-auto w-20' src="${siteUrl}spin.gif" alt="Loading..."></div>`;
    }

    async function getResponse(action, formData, page, postsPerPage) {
      if (action.includes("land")) {
        return await getLand(formData, page, postsPerPage);
      }
      return await getProperties(formData, page, postsPerPage);
    }
    const response = await getResponse(action, formData, page, postsPerPage);

    const properties = response.entries;
    total = response.entryCount;

    results.innerText = total;
    const html = properties
      .map((property, i) => propertyCard(property, propertyCardTemplate, i))
      .join("");

    if (page > 1) {
      el.insertAdjacentHTML("beforeend", html);
    } else {
      el.innerHTML = html;
      if (total == 0) {
        el.innerHTML = `<p class='text-center text-lg w-full pl-8 h3 py-20' >No properties could be found with that search criteria.</p>`;
      }
    }

    if (currentPage * postsPerPage < total && loadMore) {
      loadMore.classList.remove("hidden");
    } else {
      loadMore.classList.add("hidden");
    }
    loadMore.removeAttribute("disabled");
    console.log("update properties");
    eventBus.emit("clear-actions", el);
    eventBus.emit("run-actions", el);
  }

  eventBus.on("update-properties", updateProperties);
  eventBus.emit("listing-ready");

  if (loadMore) {
    addEvent("click", loadMore, () => {
      updateProperties(lastFormData, currentPage + 1);
      loadMore.setAttribute("disabled", true);
    });
  }

  return () => {
    eventBus.off("update-properties");
  };
}
