import { width, height } from "./consts";

export default async function getProperties(
  formData,
  page,
  postsPerPage,
  fullQuery = true
) {
  const siteUrl = window?.siteUrl ?? "/";
  const isCommercial = formData.get("action").includes("commercial");
  let query = `section: "property", type: "property", commercial: ${isCommercial}, `;
  const purchaseType = formData.get("purchaseType") ?? "";
  const beds = formData.get("beds");
  const propertyType = formData.getAll("propertyType[]");
  const reference = formData.get("search");
  const beach = formData.get("beach");

  const provinces = formData.getAll("province[]");
  const order = formData.get("order");
  const min = formData.get("min") ? formData.get("min") : 0;
  const max = formData.get("max") ? formData.get("max") : 999999999;

  if (purchaseType.length) {
    query += `purchaseType: ${purchaseType}, `;
  }

  if (beds) {
    query += `beds: [">= ${beds}"], `;
  }

  if (provinces.length) {
    query += `province: [${provinces.join(",")}], `;
  }

  if (beach) {
    query += `beachCheckbox: ${beach == "on"}, `;
  }

  if (propertyType?.length) {
    query += `propertyType: [${propertyType.join(",")}], `;
  }

  if (reference?.length) {
    query += `reference: "${reference}", `;
  }

  query += `price: ["and", ">= ${min}", "<= ${max}"], `;

  query += `orderBy: "${order}", `;

  query += `limit: ${postsPerPage}, offset: ${(page - 1) * postsPerPage}`;

  console.log(reference);

  let graphQl = "";
  if (fullQuery) {
    graphQl = `{
      entryCount(${query})
      entries(${query}) {
        url
        title
        typeHandle
        ... on property_property_Entry {
          beds
          baths
          size
          price
          poa
          bitcoin
          ethereum
          commercial
          investmentOpportunity
          province(limit: 1) {
            title
          }
          purchaseType(limit: 1) {
            id
            title
          }
          photos {
            webp: url@transform(width: ${width}, height: ${height}, format: "webp")
            jpg:  url@transform(width: ${width}, height: ${height}, format: "jpg")
          }
        }
      }
    }`;
  } else {
    graphQl = `{
      entryCount(${query})
    }`;
  }

  const response = await fetch(`${siteUrl}api`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: graphQl,
    }),
  });
  const json = await response.json();
  return json.data;
}
