import { Loader } from "google-maps";
import inview from "./utils/inview";

function GMap(el) {
  const { observe, disconnect } = inview;
  const lat = +el.dataset.lat;
  const lon = +el.dataset.lon;
  let mapHasInit = false;

  const options = {
    /* todo */
  };

  if (!window.maploader) {
    window.maploader = new Loader(
      "AIzaSyCAbT8rSNKLA327EWb2fFgb5p8LGF_pkp0",
      options
    );
  }
  // const loader = new Loader("AIzaSyCAbT8rSNKLA327EWb2fFgb5p8LGF_pkp0", options);

  function initMap() {
    console.log("init map");
    window.maploader.load().then(function (google) {
      el.style.height = "487px";
      const map = new google.maps.Map(el, {
        center: { lat: lat, lng: lon },
        zoom: 17,
        mapTypeControl: false,
      });

      new google.maps.Marker({
        position: { lat: lat, lng: lon },
        map,
      });
    });
  }

  observe(el, (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !mapHasInit) {
        initMap();
        mapHasInit = true;
        // disconnect();
      }
    });
  });

  return () => {
    console.log("destroy map");
    disconnect();
  };
}

export default GMap;
