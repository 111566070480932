import events from "./utils/events";

export default function Form(el) {
  const siteUrl = window?.siteUrl ?? "/";
  const { addEvent, removeEvents } = events;
  const error = el.querySelector("[data-errors]");
  const submit = el.querySelector('[type="submit"]');
  const success = document.querySelector(el.dataset?.success);

  el.setAttribute("method", "POST");
  const actionEl = document.createElement("input");
  actionEl.setAttribute("type", "hidden");
  actionEl.name = "action";
  actionEl.value = "contact-form/send";
  el.append(actionEl);

  addEvent("submit", el, (e) => {
    e.preventDefault();
    console.log("usmibt", el.action.value);
    submit.setAttribute("disabled", true);
    submit.querySelector(".flex").innerText = "Sending...";

    fetch(siteUrl, {
      method: "post",
      body: new FormData(el),
      headers: {
        Accept: "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        console.log(data);
        if (data?.success) showSuccess();
        if (data?.errors) showErrors(data.errors);
      })
      .catch((e) => {
        error.innerHTML = "⚠️ Something went wrong please try again.";
        submit.removeAttribute("disabled");
        submit.querySelector(".flex").innerText = "Submit";
      });
  });

  function showSuccess() {
    el.classList.add("hidden");
    success.classList.remove("hidden");
    history.pushState({}, null, "?form=success");
  }

  function showErrors(errors) {
    console.log(errors);
    let msg = "";
    for (const error in errors) {
      msg += `⚠️ ${errors[error]}<br/>`;
    }
    error.innerHTML = msg;

    submit.removeAttribute("disabled");
    submit.querySelector(".flex").innerText = "Submit";
  }

  return () => {
    removeEvents(el);
  };
}
