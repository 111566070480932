import events from "./utils/events";

export default function Popout(el) {
  const id = el.id;
  const { addEvent, removeEvents } = events;
  const closeBtn = el.querySelector(".close");
  const openBtns = [...document.querySelectorAll(`[data-popout="${id}"]`)];
  const backdrop = document.createElement("div");
  backdrop.style.position = "fixed";
  backdrop.style.left = "0";
  backdrop.style.top = "0";
  backdrop.style.right = "0";
  backdrop.style.bottom = "0";
  backdrop.style.zIndex = "49";
  backdrop.classList.add("hidden");
  document.body.appendChild(backdrop);

  addEvent("click", closeBtn, close);
  addEvent("click", backdrop, close);

  function open() {
    backdrop.classList.remove("hidden");
    document.body.classList.add("oh");
    el.classList.add("open");
    console.log("open");
  }

  function close() {
    backdrop.classList.add("hidden");
    el.classList.remove("open");
    document.body.classList.remove("oh");
  }

  openBtns.forEach((btn) => {
    addEvent("click", btn, (e) => {
      e.preventDefault();
      open();
    });
  });

  return () => {
    document.body.classList.remove("oh");
    document.body.removeChild(backdrop);
    removeEvents(closeBtn, ...openBtns);
  };
}
