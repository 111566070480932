function inview() {
  let observer;
  let options = {
    rootMargin: "0px",
    threshold: 0,
  };

  function observe(target, func) {
    observer = new IntersectionObserver(func, options);
    observer.observe(target);
  }

  function disconnect() {
    console.log("disconnecting intersection observer");
    observer?.disconnect?.();
  }

  return {
    observe,
    disconnect,
  };
}

export default inview();
